<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path
        class="st0"
        d="M157.86,24.178l-5.242-10.595c-3.43-6.887-11.794-9.731-18.682-6.302L45.325,51.197h13.551L157.86,24.178z"
      />
      <path
        class="st0"
        d="M171.244,51.197h35.132l-4.35-15.949c-1.673-6.218-7.305-10.289-13.439-10.289
		c-1.227,0-2.454,0.167-3.681,0.502l-23.254,6.357L90.634,51.197H171.244z"
      />
      <path
        class="st0"
        d="M128.388,167.635v-12.77v-4.322v-4.322v-9.592c0-6.413,2.565-12.24,6.72-16.506
		c3.681-3.792,8.616-6.357,14.137-6.999c0.92-0.111,1.868-0.167,2.816-0.167h71.157h4.322h4.322h2.956v-38.06
		c0.028-7.696-6.218-13.941-13.914-13.941h-3.179h-4.322h-4.322h-32.985H54.889H38.996H25.612H23.13h-8.309
		c-4.406,0-8.337,2.035-10.902,5.242c-1.171,1.478-2.063,3.179-2.565,5.047c-0.307,1.171-0.502,2.398-0.502,3.653v1.673v15.893
		v137.769c0,7.696,6.246,13.941,13.941,13.941h206.082c7.696,0,13.941-6.245,13.941-13.941v-38.896h-82.728
		C139.011,191.336,128.388,180.713,128.388,167.635z"
      />
      <path
        class="st0"
        d="M244.603,126.285c-1.395-1.283-3.04-2.259-4.88-2.9c-1.422-0.474-2.927-0.752-4.517-0.752h-0.362h-0.279
		h-4.322h-15.586h-62.569c-7.696,0-13.941,6.245-13.941,13.941v6.942v4.322v4.322v15.447c0,7.696,6.245,13.941,13.941,13.941h82.756
		h0.362c1.59,0,3.095-0.279,4.517-0.753c1.84-0.613,3.485-1.617,4.88-2.9c2.788-2.537,4.544-6.218,4.544-10.289v-31.033
		C249.148,132.503,247.392,128.822,244.603,126.285z M181.198,154.865c0,3.848-3.123,6.971-6.971,6.971h-4.628
		c-3.848,0-6.971-3.123-6.971-6.971v-4.628c0-2.231,1.032-4.211,2.677-5.465c1.199-0.92,2.677-1.505,4.294-1.505h1.171h3.458
		c3.848,0,6.971,3.123,6.971,6.971V154.865z"
      />
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconWallet',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

